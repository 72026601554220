@import './fonts';
@import './variables';

@import './base';
@import './container';
@import './common';
@import './button';

@import './header';
@import './try-free';
@import './thank-page';
@import './mobile-menu';
@import './scroll_up';
@import './title';
@import './hero';
@import './marquee';
@import './products';
@import './description';
@import './argument';
@import './trainer';
@import './program';
@import './inventory';
@import './gift';
@import './contraindication';
@import './package';
@import './feedback';
@import './contact';
@import './footer';
