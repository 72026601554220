.feedback {
  position: relative;
  padding: 40px 20px 100px;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 0px 60px 120px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 80px 120px;
  }

  &-pd {
    @media screen and (min-width: 768px) {
      padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
      padding-top: 80px;
    }
  }
}

.feedback-item {
  max-height: 340px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #ff7400;
  overflow-y: auto;
  overflow-x: hidden;
}

.feedback-item::-webkit-scrollbar {
  width: 10px;
  -webkit-appearance: none;
}

.feedback-item::-webkit-scrollbar-track {
  background-color: transparent;
}

.feedback-item::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #ff7400;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.wrapper .slick-track {
  display: flex;
  gap: 30px;
}

.feedback-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.feedback-descr {
  color: $black-color;
  font-size: 14px;
  line-height: 170%;
  font-weight: 500;
}

.feedback-image {
  width: 86px;
  height: 16px;
}

.wrapper .slick-dots li button:before {
  margin-top: 24px;
  font-size: 20px;
  color: $accent-bg-color;
  opacity: 1;
}
.wrapper .slick-slide {
  min-height: 340px;
}

.wrapper .slick-next {
  width: 24px;
  height: 34px;
  right: -45px;
  background: url(../images/arrow-next.svg) 0 0 no-repeat;
}

.wrapper .slick-prev {
  width: 24px;
  height: 34px;
  left: -35px;
  background: url(../images/arrow-prev.svg) 0 0 no-repeat;
}

.wrapper .slick-next:before,
.wrapper .slick-prev::before {
  content: '';
}

.wrapper .slick-prev:hover,
.wrapper .slick-prev:focus {
  background: url(../images/arrow-prev.svg) 0 0 no-repeat;
}

.wrapper .slick-next:hover,
.wrapper .slick-next:focus {
  background: url(../images/arrow-next.svg) 0 0 no-repeat;
}
