.contraindication {
  padding: 60px 20px 40px;
  background-color: $dark-bg-color;
  color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 60px;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px 80px 120px;
  }
}

.contraindication-list {
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 30px;
  row-gap: 40px;

  @media screen and (min-width: 500px) {
    column-gap: 40px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 60px;
  }

  @media screen and (min-width: 1200px) {
    column-gap: 80px;
    row-gap: 80px;
  }
}

.contraindication-item {
  text-align: center;
}

.contraindication-foto {
  margin-bottom: 12px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 24px;
  }
}

.contraindication-subtitle {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5;

  @media screen and (min-width: 768px) {
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
  }
  @media screen and (min-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 32px;
  }
}

.contraindication-descr {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    text-align: center;
  }
}
