.try-free {
  padding: 82px 20px;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 80px 40px 30px;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px 80px 45px;
  }
}

@media screen and (min-width: 768px) {
  .try-free-title {
    margin-bottom: 48px;
  }

  .try-free-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 50px;
  }

  .try-free-descr-desktop-box {
    max-width: 460px;
  }

  .try-free-descr-desktop {
    color: $black-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 170%;
  }
}

.try-free-descr {
  color: $black-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .try-free-descr-desktop {
    display: none;
  }
}

.try-video {
  display: block;
  max-width: 335px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 400px) {
    min-width: 360px;
    max-width: 420px;
  }

  @media screen and (min-width: 1200px) {
    min-width: 720px;
    max-width: 720px;
    height: 403px;
  }
}
