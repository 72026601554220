.footer {
  padding: 18px 20px 16px;
  background-color: $dark-bg-color;
  color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 33px;
  }

  @media screen and (min-width: 1200px) {
    padding: 60px 80px 32px;
  }
}

.footer-create-link {
  font-size: 10px;
  cursor: pointer;
  transition: color 0.5s ease;
  &:hover {
    color: $accent-bg-color;
  }
  @media screen and (min-width: 375px) {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .footer-create-link-hidden-mobile {
    display: none;
  }

  .footer-create-link {
    margin-bottom: 6px;
  }

  .footer-create-box {
    display: flex;
    justify-content: space-between;
  }

  .footer-create-box1 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .footer-create-link-hidden-desktop {
    display: none;
  }
  .footer-create-box1 {
    display: flex;
    justify-content: space-between;
  }
}
