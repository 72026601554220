.program,
.question {
  padding: 20px 20px 36px;
  background-color: $dark-bg-color;
  color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px;
  }

  @media screen and (min-width: 1000px) {
    padding: 80px;
  }
}

.question {
  background-color: white;
  color: $dark-bg-color;
}

.program-title {
  margin-bottom: 4px;
  @media screen and (min-width: 600px) {
    margin-bottom: 12px;
  }

  @media screen and (min-width: 1000px) {
    display: block;
    max-width: 365px;
    min-width: 365px;
    margin-bottom: 12px;
  }
}

.program-descr-time {
  margin-bottom: 13px;
  color: #c2c2c2;
  font-size: 22px;
  font-family: Druk Cyr;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.88px;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 38px;
    margin-bottom: 24px;
  }
}

.program-descr {
  margin-bottom: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;

  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
    font-size: 18px;
  }
}

.program-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;

  @media screen and (min-width: 1000px) {
    margin-bottom: 80px;
  }
}

.program-item,
.question-item {
  cursor: pointer;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border: 1px solid #eeeeee80;
  border-radius: 12px;

  @media screen and (min-width: 1000px) {
    padding: 25px 40px;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.question-item {
  border: 1px solid $dark-bg-color;
  & .question-item-title {
    margin-bottom: 12px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
      font-size: 28px;
    }
  }
}
.program-item[open] {
  border: 1px solid $accent-bg-color;
}

.program-item-title {
  font-size: 24px;
  font-family: Druk Cyr;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  transition: color 0.5s ease;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.program-item-train-title {
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  text-transform: none;

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1000px) {
    margin-top: 32px;
    height: 130px;
    font-size: 24px;
    letter-spacing: -0.48px;
  }
}

.program-item-train-descr {
  margin-top: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  color: #dadada;

  @media screen and (min-width: 768px) {
    width: 520px;
    font-size: 18px;
  }

  @media screen and (min-width: 1000px) {
    margin-left: auto;
    transform: translateY(-40px);
  }
}

.program-number {
  display: none;
}

.program-button-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}

@media screen and (min-width: 1000px) {
  .program-box2 {
    display: flex;
    gap: 100px;
  }

  .program-box1 {
    padding-top: 12px;
    max-width: 674px;
  }

  .program-descr-bold {
    font-size: 28px;
    font-weight: 800;
    line-height: 140%;
  }

  .program-number {
    display: block;
    color: rgba(255, 116, 0, 0.2);
    font-size: 100px;
    font-style: italic;
    font-weight: 900;
    line-height: 100%;
  }

  .program-number-mobile {
    display: none;
  }

  .program-box-item-desktop {
    display: flex;
    align-items: center;
    gap: 54px;
  }

  .program-details-list {
    margin-right: auto;
    margin-left: auto;
    max-width: 750px;
  }
}

.program-box-item-mobile {
  display: flex;
  gap: 4px;
}

.question-item-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  text-transform: none;
  color: black;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.question-item-title {
  font-family: Druk Cyr;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.27;
  letter-spacing: 0.03em;
  text-transform: none;

  @media (min-width: 768px) {
    font-size: 28px;
  }

  @media (min-width: 1200px) {
    font-size: 32px;
  }
}
