.header {
  z-index: 100;
  width: 100%;
  margin: 0 auto;
  border: none;
  position: absolute;
}

.header_container {
  background-color: $dark-bg-color;
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 15px;
    gap: 3px;
  }

  @media screen and (min-width: 768px) {
    padding: 6px 20px 6px 20px;
  }

  @media screen and (min-width: 1310px) {
    padding: 6px 40px 6px 40px;
  }
}

.header_container-white {
  background-color: $white-color;
}
.main-navigation {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .header_logo_container-767 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .header_logo_container-768 {
    display: none;
  }
}

.header-title {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 12px;
  color: $accent-bg-color;
  text-transform: uppercase;
  @media screen and (min-width: 440px) {
    line-height: normal;
  }

  @media screen and (min-width: 768px) {
    font-size: 10px;
    text-align: center;
  }

  @media screen and (min-width: 850px) {
    font-size: 12px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 16px;
  }
}

.header__link {
  color: $white-color;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  transition: all 0.5s ease;
  @media screen and (min-width: 850px) {
    font-size: 12px;
  }

  @media screen and (min-width: 1000px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1400px) {
    font-size: 18px;
  }

  &:hover,
  &:focus {
    color: $accent-bg-color;
    text-decoration: underline #f87a10 2px;
    text-underline-offset: 4px;
  }
}

.header__menu {
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: flex;
    gap: 10px;
  }
  @media (min-width: 900px) {
    gap: 20px;
  }
  @media (min-width: 1100px) {
    gap: 30px;
  }
  @media (min-width: 1200px) {
    gap: 50px;
  }
  @media (min-width: 1440px) {
    gap: 50px;
  }
}
