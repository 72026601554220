.trainer {
  padding: 60px 20px;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 60px;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px 80px 100px;
  }
  &-0 {
    padding-bottom: 0;
    padding-top: 0;
  }
  &-b-0 {
    padding-bottom: 0;
  }
  &--small {
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.trainer-title {
  margin-bottom: 12px;
  color: $dark-bg-color;
}

.trainer-subtitle {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
  color: $black-color;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.trainer-foto {
  margin-bottom: 32px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1000px) {
    display: none;
  }

  &--new {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
.trainer-foto--desktop {
  border-radius: 12px;
  min-width: 450px;
  max-width: 450px;
  margin-bottom: 0;
  @media screen and (max-width: 999px) {
    display: none;
  }
  @media screen and (min-width: 1100px) {
    min-width: 600px;
    max-width: 600px;
  }
  @media screen and (min-width: 1400px) {
    min-width: 750px;
    max-width: 750px;
  }

  &--new {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.trainer-buttocks-list {
  @media (min-width: 768px) {
    max-width: 452px;
  }
}

.trainer-ed-title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.3;
  color: $black-color;

  @media screen and (min-width: 768px) {
    margin-top: 32px;
    margin-bottom: 25px;
    text-align: center;
    color: $dark-bg-color;
    font-size: 32px;
    font-family: Druk Cyr;
    font-weight: 700;
    letter-spacing: 1.28px;
    text-transform: uppercase;
  }
}

.trainer-ed-item {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  color: $black-color;

  @media screen and (min-width: 768px) {
    min-width: 300px;
    max-width: 300px;
    font-size: 16px;
  }

  @media screen and (min-width: 1200px) {
    min-width: 375px;
    max-width: 375px;
  }
}

.trainer-ed-list {
  margin-bottom: 30px;
}

.trainer-expirience-list {
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 650px;
  }
}

.trainer-expirience {
  text-align: center;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.trainer-quote-box {
  position: relative;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.trainer-quote-hidden {
  display: none;
}

.trainer-quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-40%);

  @media screen and (min-width: 1200px) {
    transform: translateY(-50%) translateX(-50%);
  }
}

.trainer-quote {
  padding-left: 32px;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  color: $black-color;
  z-index: 100;

  @media screen and (min-width: 768px) {
    padding-left: 0;
  }
}

.foto-anna {
  background-color: rgb(89, 92, 110);
  background-image: url(../images/foto_anna_mobile.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  height: 357px;
  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: url(../images/foto_anna_mobile@2x.jpg);
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 500px) {
    height: 450px;
  }
  @media screen and (min-width: 600px) {
    height: 500px;
  }
}

.quote {
  position: relative;
  padding: 60px 20px 60px;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 60px;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px 80px 120px;
  }
}

.quote-icon {
  position: absolute;
  top: 0;
}

@media screen and (min-width: 768px) {
  // .trainer-desktop-box1 {
  //   max-width: 120px;
  // }

  .trainer-quote-hidden {
    display: block;
  }

  .trainer-quote {
    font-size: 16px;
  }

  .trainer-ed-list {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-bottom: 60px;
  }

  .trainer-expirience-list-desc {
    column-count: 2;
  }

  .trainer-desktop-box {
    gap: 65px;
  }

  .quote-desktop {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    background-color: $white-color;
    padding: 60px;

    @media screen and (min-width: 1000px) {
      padding: 100px;
    }
  }
  .quote-desktop-img {
    display: block;
    width: 418px;
    @media screen and (min-width: 1300px) {
      width: 618px;
    }
  }
  .quote-desktop-box {
    width: 330px;
    padding: 0;
    @media screen and (min-width: 1000px) {
      width: 430px;
    }
  }
  .quote-icon-desktop--top {
    position: absolute;
    top: -10%;
    left: -10%;
  }
  .quote-icon-desktop--bottom {
    position: absolute;
    bottom: -10%;
    right: -5%;
    transform: rotate(180deg);
  }
}
.trainer-desktop-box {
  @media screen and (min-width: 768px) {
    display: flex;
    gap: 35px;
    align-items: center;
  }
}

.trainer-desktop-box1 {
  @media screen and (min-width: 768px) {
    max-width: 400px;
  }
  @media screen and (min-width: 950px) {
    max-width: 450px;
  }
}
