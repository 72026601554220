* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Mont', sans-serif;
  letter-spacing: 0.04em;
  padding: 0;
  margin: 0;
  background-color: $dark-bg-color;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior-y: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/* Скидання базових властивостей у списків (ul або клас .list) */
ul {
  padding: 0;
  margin: 0;
}

.list {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}
