.package {
  padding: 60px 20px;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .package {
    padding: 120px 80px;
  }
  .package-title-descr {
    display: none;
  }
}

.package-premium-icon {
  position: absolute;
  border-radius: 100px;
  background: linear-gradient(184deg, #6fc7b8 0%, #fff 100%);
  display: flex;
  width: 90px;
  height: 90px;
  transform: rotate(19.378deg);
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #272727;
  text-align: center;
  text-shadow: 0px 4px 4px 0px rgba(39, 39, 39, 0.1);
  font-size: 24px;
  font-family: Druk Cyr;
  font-style: normal;
  font-weight: 900;
  line-height: 105%;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  top: -3%;
  right: -1%;

  @media screen and (min-width: 1200px) {
    font-size: 32px;
    width: 136px;
    height: 136px;
    padding: 16px 24px;
    top: -6%;
    right: -15%;
  }
}

.package-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
}

.package-item {
  position: relative;
  padding: 12px 12px 32px 12px;
  border-radius: 12px;
  border: 1px solid #6fc7b8;
  max-width: 480px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 900px) {
    width: 407px;
  }
}

.package-item-base {
  border: 1px solid #f18ea3;
}

.package-item-box {
  margin-bottom: 24px;
  text-align: center;
  padding: 24px 0;
  border-radius: 12px;
  background-color: #6fc7b8;
}

.package-item-box-base {
  background-color: #f18ea3;
}

.package-title {
  margin-bottom: 24px;
  color: $white-color;
  text-shadow: 0px 4px 4px 0px rgba(39, 39, 39, 0.1);
  font-size: 52px;
  font-family: Druk Cyr;
  font-style: italic;
  font-weight: 900;
  line-height: 96%;
  letter-spacing: 1.04px;
  text-transform: uppercase;

  @media (min-width: 900px) and (max-width: 1100px) {
    font-size: 42px;
  }
}

.package-item-descr-list {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media screen and (min-width: 768px) {
    gap: 9px;
  }
}

.package-item-descr {
  display: flex;
  gap: 6px;
  align-items: flex-start;
  color: $dark-bg-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 170%;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
}

.package-item-descr-cross {
  color: #bfbfbf;
}

.package-test-button {
  background-color: #6fc7b8;
  width: 129px;
  font-size: 16px;
  &:hover,
  &:focus {
    background-color: #029179;
  }
}

.package-base-button {
  background-color: #f18ea3;
  width: 129px;
  font-size: 16px;
  &:hover,
  &:focus {
    background-color: #ee6c88;
  }
}

.package-item-premium {
  border: 1px solid $accent-bg-color;
}

.package-item-box-premium {
  background-color: $accent-bg-color;
}

.package-title-premium {
  margin-bottom: 6px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 24px;
  }
}

.package-title-descr {
  margin-bottom: 24px;
  color: $dark-bg-color;
  text-shadow: 0px 4px 4px 0px rgba(39, 39, 39, 0.1);
  font-size: 24px;
  font-family: Druk Cyr;
  font-style: italic;
  font-weight: 700;
  line-height: 96%;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.package-premium-button {
  background-color: $accent-bg-color;
  width: 129px;
  font-size: 16px;
}

.package-price {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 193px;
  padding: 11px 26px 17px;
  background-color: $dark-bg-color;
  border-radius: 12px;
  color: #f18ea3;
  font-size: 40px;
  font-family: Druk Cyr;
  font-style: italic;
  font-weight: 1000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.package-price-blue {
  color: #6fc7b8;
}

.package-price-orange {
  color: $accent-bg-color;
}
