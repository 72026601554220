.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 175px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: $dark-bg-color;
  border: 1px solid #000;
  box-shadow: 4px 4px 0px 0px #000;
  background-color: $accent-bg-color;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.5s ease;

  @media (min-width: 768px) {
    width: 200px;
  }

  &:hover,
  &:focus {
    background-color: $white-color;
  }

  @media screen and (min-width: 1200px) {
    letter-spacing: 0.2px;
    padding: 26px 0;
    font-size: 20px;
    width: 260px;
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 0;
  }

  &--transparent {
    background-color: transparent;
    transition: background-color 0.5s ease;

    &:hover,
    &:focus {
      background-color: $accent-bg-color;
    }

    &--bottom {
      margin-bottom: 60px;

      @media (min-width: 768px) {
        margin-top: 48px;
      }
    }
  }

  &--program {
    margin-right: 0;
    margin-left: 0;

    &--white {
      background-color: $white-color;
      transition: background-color 0.5s ease;

      &:hover,
      &:focus {
        background-color: $accent-bg-color;
      }
    }
  }

  &--bottom {
    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
  }
}
