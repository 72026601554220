.title {
  margin-bottom: 24px;
  font-family: 'Druk Cyr';
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $dark-bg-color;

  @media screen and (min-width: 1000px) {
    margin-bottom: 38px;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 1.44px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 56px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 72px;
  }
}

@media screen and (min-width: 1000px) {
  .underline-title {
    text-decoration: underline #f87a10 4px;
    text-underline-offset: 8px;
  }
}

.title--small {
  text-align: center;
  font-size: 24px;
  line-height: 150%; /* 36px */
  letter-spacing: 0.48px;
  color: $dark-bg-color;
  @media screen and (min-width: 768px) {
    font-size: 36px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 42px;
  }

  &--bottom-12 {
    margin-bottom: 12px;
  }

  &--center {
    @media screen and (min-width: 768px) {
      text-align: center;
    }
  }
  &--buttocks {
    font-size: 18px;
    @media screen and (min-width: 768px) {
      font-size: 24px;
      text-align: start;
    }
  }
  @media screen and (min-width: 1200px) {
    font-size: 36px;
  }
}

.title--white {
  color: white;
}

.title--center {
  text-align: center;
}
