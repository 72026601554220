.hero {
  position: relative;
  background-color: rgb(89, 92, 110);
  padding: 140px 26px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 100px 36px 60px;
  }

  @media screen and (min-width: 1000px) {
    padding: 120px 60px 117px;
  }
  @media screen and (min-width: 1440px) {
    padding: 140px 80px 117px;
  }
}

.hero-body {
  padding: 100px 20px 80px;
  @media screen and (min-width: 400px) {
    padding: 110px 36px 60px;
  }
  @media screen and (min-width: 768px) {
    padding: 140px 40px 272px;
  }
  @media screen and (min-width: 1440px) {
    padding: 140px 120px 272px;
  }

  &-container {
    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
}

.hero-stomache {
  padding: 100px 20px 60px;
  @media screen and (min-width: 375px) {
    padding: 110px 36px 60px;
  }
  @media screen and (min-width: 768px) {
    padding: 100px 50px 50px;
  }
  @media screen and (min-width: 1200px) {
    padding: 120px 90px 50px;
  }
}

.hero-fitness {
  padding: 70px 20px 60px;

  @media screen and (min-width: 550px) {
    padding: 100px 40px 60px;
  }
  @media screen and (min-width: 768px) {
    padding: 80px 40px 60px;
  }

  &-container {
    @media (min-width: 768px) {
      margin-right: auto;
    }
  }
}

.hero-buttocks {
  padding: 80px 36px 60px;

  @media screen and (min-width: 768px) {
    padding: 100px 40px 50px;
  }
  @media screen and (min-width: 1200px) {
    padding: 120px 40px 100px 90px;
  }
  @media screen and (min-width: 1340px) {
    padding: 120px 90px 100px 90px;
  }
}

.hero-bg-main {
  background-image: url(../images/hero_mobile.jpg);
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: url(../images/hero_mobile@2x.jpg);
    }
  }

  @media screen and (min-width: 1000px) {
    max-width: 1440px;
    background-position-x: 85%;
    background-position-y: 30px;
    height: 860px;
    background-image: url(../images/hero-desktop.jpg);

    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: url(../images/hero-desktop@2x.jpg);
      }
    }
  }
}

.hero-bg-body {
  background-image: url(../images/hero-mobile-body.jpg);
  background-position-x: 50%;
  background-position-y: 30px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: url(../images/hero-mobile-body@2x.jpg);
    }
  }

  @media screen and (min-width: 600px) {
    max-width: 1440px;
    background-image: url(../images/hero-body-desktop.jpg);

    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: url(../images/hero-body-desktop@2x.jpg);
      }
    }
  }
}

.hero-bg-stomache {
  background-image: url(../images/hero-stomache-mobile.jpg);
  background-position-y: 40px;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: url(../images/hero-stomache-mobile@2x.jpg);
    }
  }

  @media screen and (min-width: 600px) {
    max-width: 1440px;
    background-position: 50%;
    background-image: url(../images/hero-stomache-desktop.jpg);

    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: url(../images/hero-stomache-desktop@2x.jpg);
      }
    }
  }
}

.hero-bg-buttocks {
  background-image: url(../images/hero-mobile-buttocks.jpg);
  background-position-x: 50%;
  background-position-y: 40px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: url(../images/hero-mobile-buttocks@2x.jpg);
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 1440px;
    background-position-y: 0;
    background-image: url(../images/hero-buttocks-desktop.jpg);

    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: url(../images/hero-buttocks-desktop@2x.jpg);
      }
    }
  }
}

.hero-bg-fitness {
  background-image: url(../images/hero-fitness-mobile.jpg);
  background-position-x: 50%;
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: url(../images/hero-fitness-mobile@2x.jpg);
    }
  }

  @media (min-width: 400px) {
    background-position-y: 20px;
  }
  @media (min-width: 450px) {
    background-position-y: 10px;
  }

  @media screen and (min-width: 550px) {
    background-position-y: 50%;
    background-position-x: 30%;
    max-width: 1440px;
    background-image: url(../images/hero-fitness-desktop.jpg);

    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: url(../images/hero-fitness-desktop@2x.jpg);
      }
    }
  }
}

.hero-title {
  text-transform: uppercase;
  font-family: 'Druk Cyr';
  font-style: italic;
  font-weight: 900;
  line-height: 1;
  -webkit-text-stroke: 1px $dark-bg-color;
  text-align: center;
  margin-bottom: 273px;

  & p {
    font-size: 38px;
    color: transparent;
    @media screen and (min-width: 768px) {
      font-size: 54px;
    }
    @media screen and (min-width: 1200px) {
      -webkit-text-stroke: 2px $dark-bg-color;
      font-size: 100px;
    }
  }

  & span {
    font-size: 56px;
    -webkit-text-stroke: 2px $dark-bg-color;

    @media screen and (min-width: 768px) {
      font-size: 72px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 100px;
      -webkit-text-stroke: 4px $dark-bg-color;
    }
  }

  &--orange {
    color: $accent-bg-color;
    font-size: 42px;
    -webkit-text-stroke: 0px;

    @media screen and (min-width: 768px) {
      font-size: 72px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 100px;
    }
  }

  &--main {
    & p,
    & span {
      font-size: 44px;
      @media screen and (min-width: 375px) {
        font-size: 48px;
      }

      @media screen and (min-width: 1000px) {
        font-size: 72px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 100px;
      }
    }

    & span {
      -webkit-text-stroke: 0;
    }

    @media screen and (min-width: 1000px) {
      text-align: end;
    }
    @media screen and (min-width: 1200px) {
      text-align: end;
    }
  }

  &--fitness {
    text-align: start;
    margin-bottom: 216px;
    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
    }

    & p {
      @media (min-width: 1200px) {
        font-size: 80px;
      }

      @media (min-width: 1440px) {
        font-size: 96px;
      }
    }
  }

  &--body {
    & p {
      @media (max-width: 767px) {
        font-size: 52px;
      }
    }
    margin-bottom: 0;

    @media screen and (min-width: 600px) {
      text-align: end;
    }
  }

  &--stomache {
    text-align: end;
    margin-bottom: 270px;

    @media screen and (min-width: 600px) {
      margin-bottom: 24px;
      text-align: start;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 57px;
    }
  }

  &--buttocks {
    text-align: start;
    margin-bottom: 6px;

    @media screen and (min-width: 768px) {
      text-align: end;
    }
  }
}

.hero-title--buttocks span {
  @media screen and (max-width: 600px) {
    display: block;
    width: 150px;
  }
}

.hero-title-container {
  max-width: 254px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 600px) {
    margin-right: 0;
  }
  @media screen and (min-width: 768px) {
    max-width: 600px;
  }

  &--buttocks {
    margin-left: 0;
    @media screen and (min-width: 768px) {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.hero-price {
  color: transparent;
  font-size: 28px;
  text-align: end;
  margin-bottom: 208px;
  letter-spacing: 0.03em;

  @media screen and (min-width: 768px) {
    font-size: 38px;
    margin-bottom: 24px;
  }

  @media screen and (min-width: 1200px) {
    -webkit-text-stroke: 2px $dark-bg-color;
    font-size: 72px;
    margin-bottom: 42px;
  }

  &--buttocks {
    color: $accent-bg-color;
    text-align: start;
    margin-bottom: 120px;
    @media screen and (min-width: 768px) {
      text-align: end;
      margin-bottom: 160px;
    }
    @media screen and (min-width: 1200px) {
      margin-bottom: 150px;
    }
  }
}

.hero-descr {
  display: block;
  max-width: 259px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.28px;
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 767px) {
    padding: 10px 16px;
    background-color: $white-color;
    border-radius: 0px 28px 28px 28px;
    box-shadow: 4px 4px 0px 0px #000;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 150%; /* 42px */
    margin-right: 0;
    font-weight: 500;
  }
  @media screen and (min-width: 1200px) {
    font-size: 24px;
    max-width: 400px;
  }

  &--body {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 32px;

    @media screen and (min-width: 1200px) {
      margin-bottom: 32px;
      font-size: 24px;
    }
  }

  &--fitness {
    max-width: 312px;
    @media screen and (max-width: 767px) {
      border-radius: 28px 0px 28px 28px;
      box-shadow: 4px 4px 0px 0px #000;
    }
    @media screen and (min-width: 768px) {
      text-align: start;
      margin-bottom: 29px;
      margin-left: 0;
      max-width: 290px;
    }
    @media screen and (min-width: 1000px) {
      max-width: 320px;
    }
    @media screen and (min-width: 1200px) {
      max-width: 440px;
      font-size: 20px;
    }

    @media screen and (min-width: 1440px) {
      max-width: 500px;
      font-size: 24px;
    }
  }

  &--stomache {
    @media screen and (min-width: 600px) {
      margin-right: 0;
      margin-left: 0;
      background-color: transparent;
      text-align: start;
      box-shadow: none;
      margin-bottom: 186px;
      padding: 0;
      font-weight: 500;
      line-height: 150%;
      max-width: 220px;
    }
    @media screen and (min-width: 768px) {
      max-width: 320px;
    }
    @media screen and (min-width: 1200px) {
      max-width: 464px;
    }
  }

  &--buttocks {
    max-width: 252px;
    margin-bottom: 100px;

    &--orange {
      color: $accent-bg-color;
    }
    @media screen and (max-width: 767px) {
      border-radius: 28px 0px 28px 28px;
      box-shadow: 4px 4px 0px 0px #000;
    }
    @media screen and (min-width: 500px) {
      margin-bottom: 150px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
      margin-left: 0;
      text-align: start;
      max-width: 310px;
    }
    @media screen and (min-width: 1200px) {
      margin-bottom: -30px;
      max-width: 464px;
    }
  }
}
