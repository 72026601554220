.inventory {
  padding: 60px 20px 102px;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 80px 40px 60px;
  }

  @media screen and (min-width: 1200px) {
    padding: 160px 80px 100px;
  }
}

.inventory-box {
  @media screen and (min-width: 768px) {
    display: flex;
    gap: 60px;
  }
  @media screen and (min-width: 1200px) {
    gap: 113px;
  }
}

.inventory-list {
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.inventory-descr {
  color: $black-color;
  font-size: 14px;
  font-family: Mont;
  font-weight: 500;
  line-height: 170%;

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }
}

.inventory-box1 {
  margin-bottom: 54px;
  @media screen and (min-width: 1200px) {
    margin-bottom: 68px;
    width: 520px;
  }
}

.inventory-image {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 32px;
}

.inventory-item-title {
  margin-bottom: 20px;
  color: $dark-bg-color;
  font-size: 24px;
  font-family: Druk Cyr;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  text-align: center;

  @media screen and (min-width: 768px) {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 240px;
  }

  @media screen and (min-width: 1200px) {
    width: 280px;
    font-size: 32px;
    margin-bottom: 38px;
  }
}

.bottom-0 {
  margin-bottom: 0;
}
