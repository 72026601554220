.products {
  padding: 60px 20px 0;
  background-color: $white-color;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px 80px 0;
  }
}

.products-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 16px;
  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
    gap: 30px;
    // justify-content: start;
  }
  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
    gap: 30px;
  }
}
.products-item {
  display: flex;
  flex-direction: column;
  background-color: $accent-bg-color;
  width: 130px;
  border-radius: 8px;

  @media screen and (min-width: 375px) {
    width: 158px;
  }

  @media screen and (min-width: 540px) {
    width: 190px;
  }

  @media screen and (min-width: 640px) {
    width: 210px;
  }

  @media screen and (min-width: 700px) {
    width: 140px;
  }

  @media screen and (min-width: 720px) {
    border-radius: 12px;
    box-shadow: 4px 4px 0px 0px #000;
    width: 158px;
  }

  @media screen and (min-width: 800px) {
    width: 170px;
  }
  @media screen and (min-width: 900px) {
    width: 190px;
  }
  @media screen and (min-width: 1000px) {
    width: 210px;
  }
  @media screen and (min-width: 1100px) {
    width: 230px;
  }
  @media screen and (min-width: 1200px) {
    width: 270px;
  }
  @media screen and (min-width: 1440px) {
    width: 298px;
  }

  &--notactive {
    pointer-events: none;
    opacity: 0.35;
  }

  &__image {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
  }

  &__text {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: $dark-bg-color;
    min-height: 70px;
    @media screen and (min-width: 375px) {
      font-size: 16px;
    }
    @media (min-width: 900px) {
      min-height: 84px;
      font-size: 20px;
    }

    @media (min-width: 1200px) {
      font-size: 26px;
      min-height: 132px;
    }
    transition: color 0.5s ease;

    &:hover {
      color: $white-color;
    }
  }
}

.overlay {
  position: relative;
}

.soon {
  position: absolute;
  top: 0;
}
