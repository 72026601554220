//======================Roboto======================
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/roboto-v30-latin-900italic.woff2') format('woff2');
  font-display: swap;
}

//======================Druk Cyr======================

@font-face {
  font-family: 'Druk Cyr';
  src: url('../fonts/DrukCyr-Bold.eot');
  src: local('../fonts/Druk Cyr Bold'), local('../fonts/DrukCyr-Bold'),
    url('../fonts/DrukCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukCyr-Bold.woff2') format('woff2'),
    url('../fonts/DrukCyr-Bold.woff') format('woff'),
    url('../fonts/DrukCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Cyr';
  src: url('../fonts/DrukCyr-Bold.eot');
  src: local('../fonts/Druk Cyr Bold'), local('../fonts/DrukCyr-Bold'),
    url('../fonts/DrukCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukCyr-Bold.woff2') format('woff2'),
    url('../fonts/DrukCyr-Bold.woff') format('woff'),
    url('../fonts/DrukCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Cyr';
  src: url('../fonts/DrukCyr-HeavyItalic.eot');
  src: local('../fonts/Druk Cyr Heavy Italic'),
    local('../fonts/DrukCyr-HeavyItalic'),
    url('../fonts/DrukCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukCyr-HeavyItalic.woff2') format('woff2'),
    url('../fonts/DrukCyr-HeavyItalic.woff') format('woff'),
    url('../fonts/DrukCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Cyr';
  src: url('../fonts/DrukCyr-BoldItalic.eot');
  src: local('../fonts/Druk Cyr Bold Italic'),
    local('../fonts/DrukCyr-BoldItalic'),
    url('../fonts/DrukCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukCyr-BoldItalic.woff2') format('woff2'),
    url('../fonts/DrukCyr-BoldItalic.woff') format('woff'),
    url('../fonts/DrukCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

//======================Mont======================

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Black.eot');
  src: local('../fonts/Mont Black'), local('../fonts/Mont-Black'),
    url('../fonts/Mont-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-Black.woff2') format('woff2'),
    url('../fonts/Mont-Black.woff') format('woff'),
    url('../fonts/Mont-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Bold.eot');
  src: local('../fonts/Mont Bold'), local('../fonts/Mont-Bold'),
    url('../fonts/Mont-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-Bold.woff2') format('woff2'),
    url('../fonts/Mont-Bold.woff') format('woff'),
    url('../fonts/Mont-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Heavy.eot');
  src: local('../fonts/Mont Heavy'), local('../fonts/Mont-Heavy'),
    url('../fonts/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-Heavy.woff2') format('woff2'),
    url('../fonts/Mont-Heavy.woff') format('woff'),
    url('../fonts/Mont-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Regular.eot');
  src: local('../fonts/Mont Regular'), local('../fonts/Mont-Regular'),
    url('../fonts/Mont-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-Regular.woff2') format('woff2'),
    url('../fonts/Mont-Regular.woff') format('woff'),
    url('../fonts/Mont-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-RegularItalic.eot');
  src: local('../fonts/Mont Regular Italic'),
    local('../fonts/Mont-RegularItalic'),
    url('../fonts/Mont-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-RegularItalic.woff2') format('woff2'),
    url('../fonts/Mont-RegularItalic.woff') format('woff'),
    url('../fonts/Mont-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-BlackItalic.eot');
  src: local('../fonts/Mont Black Italic'), local('Mont-BlackItalic'),
    url('../fonts/Mont-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-BlackItalic.woff2') format('woff2'),
    url('../fonts/Mont-BlackItalic.woff') format('woff'),
    url('../fonts/Mont-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
