.marquee {
  overflow: hidden;
  padding: 5px 0px;
  display: flex;
  background-color: $dark-bg-color;

  @media screen and (min-width: 1200px) {
    padding: 10px 0;
  }
}

.marquee_text {
  font-family: 'Druk Cyr';
  font-style: italic;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $accent-light-color;
  white-space: nowrap;

  @media screen and (min-width: 1200px) {
    font-size: 32px;
    letter-spacing: 0.5px;
  }
}

// .poster {
//   // bottom: 0;
//   // right: 0;
//   // left: 0;
//   padding: 0;
//   background-color: $white-color;
// }

// .marquee-poster-dark {
//   // position: absolute;
//   // top: -120px;
//   // left: 0;
//   // right: 0;
//   transform: translate(0, 35px) rotate(6deg);

//   @media screen and (min-width: 768px) {
//     transform: translate(0, 40px) rotate(4deg);
//   }

//   @media screen and (min-width: 1200px) {
//     padding: 6px 0;
//     transform: translate(0, 50px) rotate(4deg);
//   }
// }

// .marquee-poster-accent {
//   // position: absolute;
//   transform: translate(0, 5px) rotate(-6deg);
//   background-color: $accent-light-color;

//   @media screen and (min-width: 768px) {
//     transform: translate(0, 0) rotate(-4deg);
//   }

//   @media screen and (min-width: 1200px) {
//     padding: 6px 0;
//     transform: translate(0, 0) rotate(-4deg);
//   }
// }

// .marquee_text-light {
//   color: $white-color;
// }
