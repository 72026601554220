.menu__button {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $accent-light-color;

  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
}

@media (min-width: 768px) {
  .menu__button {
    display: none;
  }
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 16px 24px;
  background-color: $dark-bg-color;
  z-index: 999;
  overflow: auto;

  transform: translateX(150%);
  transition: transform 250ms ease-in-out;
}

.menu-container.is-open {
  transform: translateX(0);
}

.menu-header_logo_container {
  display: flex;
  justify-content: space-between;
}

.menu-container .menu__button {
  display: flex;
}

.header__menu_mobile {
  margin: 40px 0 60px;
  padding: 30px 30px;
  border-left: 1px solid $accent-bg-color;
  border-right: 1px solid $accent-bg-color;
  @media screen and (max-width: 375px) {
    margin: 20px 0 20px;
    padding: 10px;
  }
}

.mobile-menu__link {
  display: block;
  margin-bottom: 24px;
  color: #ff7400;
  font-size: 24px;
  font-family: Druk Cyr;
  font-weight: 700;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.mobile-menu-contact {
  margin-bottom: 24px;
  padding: 30px 0;
  color: $accent-bg-color;
  border-top: 1px solid $accent-bg-color;
  border-bottom: 1px solid $accent-bg-color;
}

.marquee-bg-color {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  );
}

.mobile-contact-link.link {
  font-size: 14px;
  font-weight: 500;
}

.mobile-contact-list-item {
  margin-bottom: 12px;
}
