.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 767px;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 1439px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
}
