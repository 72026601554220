@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  src: url("roboto-v30-latin-900italic.21206037.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Druk Cyr;
  src: url("DrukCyr-Bold.5f04753b.eot");
  src: local("../fonts/Druk Cyr Bold"), local("../fonts/DrukCyr-Bold"), url("DrukCyr-Bold.5f04753b.eot#iefix") format("embedded-opentype"), url("DrukCyr-Bold.bfa7777e.woff2") format("woff2"), url("DrukCyr-Bold.00bd364e.woff") format("woff"), url("DrukCyr-Bold.8a691f76.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Druk Cyr;
  src: url("DrukCyr-Bold.5f04753b.eot");
  src: local("../fonts/Druk Cyr Bold"), local("../fonts/DrukCyr-Bold"), url("DrukCyr-Bold.5f04753b.eot#iefix") format("embedded-opentype"), url("DrukCyr-Bold.bfa7777e.woff2") format("woff2"), url("DrukCyr-Bold.00bd364e.woff") format("woff"), url("DrukCyr-Bold.8a691f76.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Druk Cyr;
  src: url("DrukCyr-HeavyItalic.4572e980.eot");
  src: local("../fonts/Druk Cyr Heavy Italic"), local("../fonts/DrukCyr-HeavyItalic"), url("DrukCyr-HeavyItalic.4572e980.eot#iefix") format("embedded-opentype"), url("DrukCyr-HeavyItalic.bc0d8193.woff2") format("woff2"), url("DrukCyr-HeavyItalic.d3e15ac3.woff") format("woff"), url("DrukCyr-HeavyItalic.8958951d.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Druk Cyr;
  src: url("DrukCyr-BoldItalic.e7ec0ea8.eot");
  src: local("../fonts/Druk Cyr Bold Italic"), local("../fonts/DrukCyr-BoldItalic"), url("DrukCyr-BoldItalic.e7ec0ea8.eot#iefix") format("embedded-opentype"), url("DrukCyr-BoldItalic.8cedb02e.woff2") format("woff2"), url("DrukCyr-BoldItalic.feb252f3.woff") format("woff"), url("DrukCyr-BoldItalic.ecc305cc.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Black.1364a9b6.eot");
  src: local("../fonts/Mont Black"), local("../fonts/Mont-Black"), url("Mont-Black.1364a9b6.eot#iefix") format("embedded-opentype"), url("Mont-Black.6f7eb178.woff2") format("woff2"), url("Mont-Black.c0502c5b.woff") format("woff"), url("Mont-Black.e6add188.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Bold.7080ea5f.eot");
  src: local("../fonts/Mont Bold"), local("../fonts/Mont-Bold"), url("Mont-Bold.7080ea5f.eot#iefix") format("embedded-opentype"), url("Mont-Bold.079e6e11.woff2") format("woff2"), url("Mont-Bold.ab4d4e6a.woff") format("woff"), url("Mont-Bold.2c75e4d5.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Heavy.e5946d1e.eot");
  src: local("../fonts/Mont Heavy"), local("../fonts/Mont-Heavy"), url("Mont-Heavy.e5946d1e.eot#iefix") format("embedded-opentype"), url("Mont-Heavy.f781317f.woff2") format("woff2"), url("Mont-Heavy.41451ca2.woff") format("woff"), url("Mont-Heavy.ee2afd7a.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Regular.f51c8c5c.eot");
  src: local("../fonts/Mont Regular"), local("../fonts/Mont-Regular"), url("Mont-Regular.f51c8c5c.eot#iefix") format("embedded-opentype"), url("Mont-Regular.842fba01.woff2") format("woff2"), url("Mont-Regular.bb2562d9.woff") format("woff"), url("Mont-Regular.b75f73be.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Mont;
  src: url("Mont-RegularItalic.b1512dad.eot");
  src: local("../fonts/Mont Regular Italic"), local("../fonts/Mont-RegularItalic"), url("Mont-RegularItalic.b1512dad.eot#iefix") format("embedded-opentype"), url("Mont-RegularItalic.af30cb7d.woff2") format("woff2"), url("Mont-RegularItalic.4008fff6.woff") format("woff"), url("Mont-RegularItalic.55d799f6.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Mont;
  src: url("Mont-BlackItalic.b08eaf39.eot");
  src: local("../fonts/Mont Black Italic"), local(Mont-BlackItalic), url("Mont-BlackItalic.b08eaf39.eot#iefix") format("embedded-opentype"), url("Mont-BlackItalic.37a199ca.woff2") format("woff2"), url("Mont-BlackItalic.49dc5e71.woff") format("woff"), url("Mont-BlackItalic.7cac2cf6.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: .04em;
  height: 100%;
  overscroll-behavior-y: none;
  background-color: #272727;
  margin: 0;
  padding: 0;
  font-family: Mont, sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}

.list {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

address {
  font-style: normal;
}

.container {
  max-width: 767px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 1439px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

.accent-title {
  text-transform: uppercase;
  color: #272727;
  margin-bottom: 12px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .accent-title {
    margin-bottom: 18px;
    font-size: 32px;
  }
}

.accent-list {
  max-width: 736px;
  background-color: #fff;
  border-top: 2px solid #ff7400;
  border-left: 2px solid #ff7400;
  border-radius: 12px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-shadow: 4px 4px 0 2px #f87100;
}

.accent-list:not(:last-child) {
  margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .accent-list {
    max-width: 736px;
    gap: 16px;
    margin-bottom: 38px;
    margin-left: 0;
    margin-right: 0;
    padding: 30px;
  }

  .accent-list:not(:last-child) {
    margin-bottom: 38px;
  }
}

.accent-list--transparent {
  box-shadow: none;
  border: none;
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
}

.accent-list--transparent:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .accent-list--transparent {
    min-width: 0;
    max-width: 736px;
  }
}

@media screen and (min-width: 900px) {
  .accent-list--transparent {
    max-width: 440px;
  }
}

.accent-list--center {
  margin-left: auto;
  margin-right: auto;
}

.accent-item {
  color: #272727;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media screen and (min-width: 768px) {
  .accent-item {
    gap: 16px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .accent-item {
    margin-bottom: 26px;
    font-size: 18px;
  }
}

.accent-item--trainer {
  align-items: flex-start;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}

@media screen and (min-width: 768px) {
  .accent-item--trainer {
    font-size: 16px;
  }
}

.accent-icon {
  min-width: 12px;
  min-height: 12px;
}

@media screen and (min-width: 768px) {
  .accent-icon {
    min-width: 16px;
    min-height: 16px;
  }
}

.accent-item:last-child {
  margin-bottom: 0;
}

.hidden {
  display: none;
}

.bottom-12 {
  margin-bottom: 12px;
}

.bottom-24 {
  margin-bottom: 24px;
}

.bottom-0 {
  margin-bottom: 0;
}

.line {
  height: 100px;
  max-width: 1440px;
  background-image: url("line-bg.74c46f23.jpg");
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  .line {
    height: 130px;
  }
}

@media screen and (min-width: 1000px) {
  .line {
    height: 170px;
  }
}

@media screen and (min-width: 1300px) {
  .line {
    height: 190px;
  }
}

.line--white {
  background-image: url("line-white.7115201d.jpg");
}

.flag {
  height: 40px;
  width: 180px;
  background-color: #ff7400;
  position: relative;
}

.economy-text {
  letter-spacing: .03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-family: Druk Cyr;
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%;
  position: absolute;
  top: 7px;
  left: 25px;
}

.triangle {
  width: 0;
  height: 0;
  border: 20px solid rgba(0, 0, 0, 0);
  border-left-color: #fff;
  border-right-width: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.economy {
  position: absolute;
  top: -22px;
  right: -2px;
}

@media screen and (min-width: 1200px) {
  .economy {
    top: -21px;
  }
}

.economy-line {
  width: 40px;
  height: 2px;
  background-color: #ff7400;
  transform: translate(-50%);
}

@media screen and (min-width: 1200px) {
  .economy-line {
    height: 1px;
  }
}

.button {
  width: 175px;
  text-align: center;
  text-transform: uppercase;
  color: #272727;
  cursor: pointer;
  background-color: #ff7400;
  border: 1px solid #000;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
  transition: background-color .5s;
  display: block;
  box-shadow: 4px 4px #000;
}

@media (min-width: 768px) {
  .button {
    width: 200px;
  }
}

.button:hover, .button:focus {
  background-color: #fff;
}

@media screen and (min-width: 1200px) {
  .button {
    letter-spacing: .2px;
    width: 260px;
    padding: 26px 0;
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .button {
    margin-bottom: 0;
  }
}

.button--transparent {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .5s;
}

.button--transparent:hover, .button--transparent:focus {
  background-color: #ff7400;
}

.button--transparent--bottom {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .button--transparent--bottom {
    margin-top: 48px;
  }
}

.button--program {
  margin-left: 0;
  margin-right: 0;
}

.button--program--white {
  background-color: #fff;
  transition: background-color .5s;
}

.button--program--white:hover, .button--program--white:focus {
  background-color: #ff7400;
}

@media (max-width: 767px) {
  .button--bottom {
    margin-bottom: 60px;
  }
}

.header {
  z-index: 100;
  width: 100%;
  border: none;
  margin: 0 auto;
  position: absolute;
}

.header_container {
  background-color: #272727;
}

@media screen and (max-width: 767px) {
  .header_container {
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    padding: 8px 10px 8px 15px;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .header_container {
    padding: 6px 20px;
  }
}

@media screen and (min-width: 1310px) {
  .header_container {
    padding: 6px 40px;
  }
}

.header_container-white {
  background-color: #fff;
}

.main-navigation {
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .header_logo_container-767 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .header_logo_container-768 {
    display: none;
  }
}

.header-title {
  color: #ff7400;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 900;
}

@media screen and (min-width: 440px) {
  .header-title {
    line-height: normal;
  }
}

@media screen and (min-width: 768px) {
  .header-title {
    text-align: center;
    font-size: 10px;
  }
}

@media screen and (min-width: 850px) {
  .header-title {
    font-size: 12px;
  }
}

@media screen and (min-width: 1440px) {
  .header-title {
    font-size: 16px;
  }
}

.header__link {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  transition: all .5s;
}

@media screen and (min-width: 850px) {
  .header__link {
    font-size: 12px;
  }
}

@media screen and (min-width: 1000px) {
  .header__link {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  .header__link {
    font-size: 18px;
  }
}

.header__link:hover, .header__link:focus {
  color: #ff7400;
  text-underline-offset: 4px;
  text-decoration: underline #f87a10;
  text-decoration-thickness: 2px;
}

.header__menu {
  align-items: center;
}

@media (max-width: 767px) {
  .header__menu {
    display: none;
  }
}

@media (min-width: 768px) {
  .header__menu {
    gap: 10px;
    display: flex;
  }
}

@media (min-width: 900px) {
  .header__menu {
    gap: 20px;
  }
}

@media (min-width: 1100px) {
  .header__menu {
    gap: 30px;
  }
}

@media (min-width: 1200px) {
  .header__menu {
    gap: 50px;
  }
}

@media (min-width: 1440px) {
  .header__menu {
    gap: 50px;
  }
}

.try-free {
  background-color: #fff;
  padding: 82px 20px;
}

@media screen and (min-width: 768px) {
  .try-free {
    padding: 80px 40px 30px;
  }
}

@media screen and (min-width: 1200px) {
  .try-free {
    padding: 100px 80px 45px;
  }
}

@media screen and (min-width: 768px) {
  .try-free-title {
    margin-bottom: 48px;
  }

  .try-free-box {
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;
    display: flex;
  }

  .try-free-descr-desktop-box {
    max-width: 460px;
  }

  .try-free-descr-desktop {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 170%;
  }
}

.try-free-descr {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}

@media screen and (min-width: 768px) {
  .try-free-descr {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .try-free-descr-desktop {
    display: none;
  }
}

.try-video {
  max-width: 335px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (min-width: 400px) {
  .try-video {
    min-width: 360px;
    max-width: 420px;
  }
}

@media screen and (min-width: 1200px) {
  .try-video {
    min-width: 720px;
    max-width: 720px;
    height: 403px;
  }
}

.thank-page {
  text-align: center;
  background-color: #fff;
  padding: 108px 20px 155px;
}

@media screen and (min-width: 1200px) {
  .thank-page {
    padding: 160px 80px 166px;
  }
}

.thank-page-title {
  color: #ff7400;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-family: Druk Cyr;
  font-size: 52px;
  font-style: italic;
  font-weight: 900;
  line-height: 96%;
}

@media screen and (min-width: 1200px) {
  .thank-page-title {
    font-size: 72px;
    line-height: normal;
  }
}

.thank-page-subtitle-box {
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .thank-page-subtitle-box {
    gap: 32px;
    margin-bottom: 48px;
  }
}

.thank-page-subtitle {
  width: 202px;
  color: #000;
  letter-spacing: -.28px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  display: block;
}

@media screen and (min-width: 1200px) {
  .thank-page-subtitle {
    width: 342px;
    letter-spacing: -.48px;
    font-size: 24px;
  }
}

.thank-page-descr {
  color: #000;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (min-width: 1200px) {
  .thank-page-descr {
    margin-bottom: 48px;
    font-size: 18px;
  }
}

.menu__button {
  fill: #f87a11;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (min-width: 768px) {
  .menu__button {
    display: none;
  }
}

.menu-container {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #272727;
  padding: 16px 24px;
  transition: transform .25s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  transform: translateX(150%);
}

.menu-container.is-open {
  transform: translateX(0);
}

.menu-header_logo_container {
  justify-content: space-between;
  display: flex;
}

.menu-container .menu__button {
  display: flex;
}

.header__menu_mobile {
  border-left: 1px solid #ff7400;
  border-right: 1px solid #ff7400;
  margin: 40px 0 60px;
  padding: 30px;
}

@media screen and (max-width: 375px) {
  .header__menu_mobile {
    margin: 20px 0;
    padding: 10px;
  }
}

.mobile-menu__link {
  color: #ff7400;
  letter-spacing: .96px;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 700;
  display: block;
}

.mobile-menu-contact {
  color: #ff7400;
  border-top: 1px solid #ff7400;
  border-bottom: 1px solid #ff7400;
  margin-bottom: 24px;
  padding: 30px 0;
}

.marquee-bg-color {
  background-image: linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7));
}

.mobile-contact-link.link {
  font-size: 14px;
  font-weight: 500;
}

.mobile-contact-list-item {
  margin-bottom: 12px;
}

.back-to-top-container {
  position: relative;
}

.back-to-top {
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 1;
  background-color: #5a5a5a;
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  transition: opacity .5s;
  display: flex;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  transform: translateX(-10px)translateY(-50px);
}

.hidden-scroll {
  opacity: 0;
}

.back-to-top-icon {
  fill: #ff7400;
}

.back-to-top:hover {
  opacity: .6;
}

.title {
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #272727;
  margin-bottom: 24px;
  font-family: Druk Cyr;
  font-size: 42px;
  font-weight: 700;
  line-height: 1;
}

@media screen and (min-width: 1000px) {
  .title {
    letter-spacing: 1.44px;
    margin-bottom: 38px;
    font-size: 48px;
    line-height: 120%;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 56px;
  }
}

@media screen and (min-width: 1440px) {
  .title {
    font-size: 72px;
  }
}

@media screen and (min-width: 1000px) {
  .underline-title {
    text-underline-offset: 8px;
    text-decoration: underline #f87a10;
    text-decoration-thickness: 4px;
  }
}

.title--small {
  text-align: center;
  letter-spacing: .48px;
  color: #272727;
  font-size: 24px;
  line-height: 150%;
}

@media screen and (min-width: 768px) {
  .title--small {
    font-size: 36px;
  }
}

@media screen and (min-width: 1200px) {
  .title--small {
    font-size: 42px;
  }
}

.title--small--bottom-12 {
  margin-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .title--small--center {
    text-align: center;
  }
}

.title--small--buttocks {
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .title--small--buttocks {
    font-size: 24px;
  }

  .title--small--buttocks:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .title--small--buttocks:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

@media screen and (min-width: 1200px) {
  .title--small {
    font-size: 36px;
  }
}

.title--white {
  color: #fff;
}

.title--center {
  text-align: center;
}

.hero {
  background-color: #595c6e;
  flex-direction: column;
  justify-content: space-between;
  padding: 140px 26px 80px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .hero {
    padding: 100px 36px 60px;
  }
}

@media screen and (min-width: 1000px) {
  .hero {
    padding: 120px 60px 117px;
  }
}

@media screen and (min-width: 1440px) {
  .hero {
    padding: 140px 80px 117px;
  }
}

.hero-body {
  padding: 100px 20px 80px;
}

@media screen and (min-width: 400px) {
  .hero-body {
    padding: 110px 36px 60px;
  }
}

@media screen and (min-width: 768px) {
  .hero-body {
    padding: 140px 40px 272px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-body {
    padding: 140px 120px 272px;
  }
}

@media (min-width: 768px) {
  .hero-body-container {
    margin-left: auto;
  }
}

.hero-stomache {
  padding: 100px 20px 60px;
}

@media screen and (min-width: 375px) {
  .hero-stomache {
    padding: 110px 36px 60px;
  }
}

@media screen and (min-width: 768px) {
  .hero-stomache {
    padding: 100px 50px 50px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-stomache {
    padding: 120px 90px 50px;
  }
}

.hero-fitness {
  padding: 70px 20px 60px;
}

@media screen and (min-width: 550px) {
  .hero-fitness {
    padding: 100px 40px 60px;
  }
}

@media screen and (min-width: 768px) {
  .hero-fitness {
    padding: 80px 40px 60px;
  }
}

@media (min-width: 768px) {
  .hero-fitness-container {
    margin-right: auto;
  }
}

.hero-buttocks {
  padding: 80px 36px 60px;
}

@media screen and (min-width: 768px) {
  .hero-buttocks {
    padding: 100px 40px 50px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-buttocks {
    padding: 120px 40px 100px 90px;
  }
}

@media screen and (min-width: 1340px) {
  .hero-buttocks {
    padding: 120px 90px 100px;
  }
}

.hero-bg-main {
  background-image: url("hero_mobile.35e3b1d2.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .hero-bg-main {
    background-image: url("hero_mobile@2x.8476229f.jpg");
  }
}

@media screen and (min-width: 1000px) {
  .hero-bg-main {
    max-width: 1440px;
    height: 860px;
    background-image: url("hero-desktop.fbd58af5.jpg");
    background-position: 85% 30px;
  }
}

@media screen and (min-width: 1000px) and (min-device-pixel-ratio: 2), screen and (min-width: 1000px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1000px) and (min-resolution: 192dpi), screen and (min-width: 1000px) and (min-resolution: 2x) {
  .hero-bg-main {
    background-image: url("hero-desktop@2x.57bc0c3d.jpg");
  }
}

.hero-bg-body {
  background-image: url("hero-mobile-body.b664ff04.jpg");
  background-position: 50% 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .hero-bg-body {
    background-image: url("hero-mobile-body@2x.04056908.jpg");
  }
}

@media screen and (min-width: 600px) {
  .hero-bg-body {
    max-width: 1440px;
    background-image: url("hero-body-desktop.62ab1541.jpg");
  }
}

@media screen and (min-width: 600px) and (min-device-pixel-ratio: 2), screen and (min-width: 600px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 600px) and (min-resolution: 192dpi), screen and (min-width: 600px) and (min-resolution: 2x) {
  .hero-bg-body {
    background-image: url("hero-body-desktop@2x.5b5852cf.jpg");
  }
}

.hero-bg-stomache {
  background-image: url("hero-stomache-mobile.9303e35c.jpg");
  background-position: 50% 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .hero-bg-stomache {
    background-image: url("hero-stomache-mobile@2x.930dba34.jpg");
  }
}

@media screen and (min-width: 600px) {
  .hero-bg-stomache {
    max-width: 1440px;
    background-image: url("hero-stomache-desktop.651f203c.jpg");
    background-position: 50%;
  }
}

@media screen and (min-width: 600px) and (min-device-pixel-ratio: 2), screen and (min-width: 600px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 600px) and (min-resolution: 192dpi), screen and (min-width: 600px) and (min-resolution: 2x) {
  .hero-bg-stomache {
    background-image: url("hero-stomache-desktop@2x.4390416a.jpg");
  }
}

.hero-bg-buttocks {
  background-image: url("hero-mobile-buttocks.83a74ed1.jpg");
  background-position: 50% 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .hero-bg-buttocks {
    background-image: url("hero-mobile-buttocks@2x.53496891.jpg");
  }
}

@media screen and (min-width: 768px) {
  .hero-bg-buttocks {
    max-width: 1440px;
    background-image: url("hero-buttocks-desktop.a3bc1f8a.jpg");
    background-position-y: 0;
  }
}

@media screen and (min-width: 768px) and (min-device-pixel-ratio: 2), screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi), screen and (min-width: 768px) and (min-resolution: 2x) {
  .hero-bg-buttocks {
    background-image: url("hero-buttocks-desktop@2x.b5e3cdd7.jpg");
  }
}

.hero-bg-fitness {
  background-image: url("hero-fitness-mobile.6e0b6855.jpg");
  background-position: 50% 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .hero-bg-fitness {
    background-image: url("hero-fitness-mobile@2x.9663a720.jpg");
  }
}

@media (min-width: 400px) {
  .hero-bg-fitness {
    background-position-y: 20px;
  }
}

@media (min-width: 450px) {
  .hero-bg-fitness {
    background-position-y: 10px;
  }
}

@media screen and (min-width: 550px) {
  .hero-bg-fitness {
    max-width: 1440px;
    background-image: url("hero-fitness-desktop.ce5a88e5.jpg");
    background-position: 30%;
  }
}

@media screen and (min-width: 550px) and (min-device-pixel-ratio: 2), screen and (min-width: 550px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 550px) and (min-resolution: 192dpi), screen and (min-width: 550px) and (min-resolution: 2x) {
  .hero-bg-fitness {
    background-image: url("hero-fitness-desktop@2x.0b27f15e.jpg");
  }
}

.hero-title {
  text-transform: uppercase;
  -webkit-text-stroke: 1px #272727;
  text-align: center;
  margin-bottom: 273px;
  font-family: Druk Cyr;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
}

.hero-title p {
  color: rgba(0, 0, 0, 0);
  font-size: 38px;
}

@media screen and (min-width: 768px) {
  .hero-title p {
    font-size: 54px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-title p {
    -webkit-text-stroke: 2px #272727;
    font-size: 100px;
  }
}

.hero-title span {
  -webkit-text-stroke: 2px #272727;
  font-size: 56px;
}

@media screen and (min-width: 768px) {
  .hero-title span {
    font-size: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-title span {
    -webkit-text-stroke: 4px #272727;
    font-size: 100px;
  }
}

.hero-title--orange {
  color: #ff7400;
  -webkit-text-stroke: 0px;
  font-size: 42px;
}

@media screen and (min-width: 768px) {
  .hero-title--orange {
    font-size: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-title--orange {
    font-size: 100px;
  }
}

.hero-title--main p, .hero-title--main span {
  font-size: 44px;
}

@media screen and (min-width: 375px) {
  .hero-title--main p, .hero-title--main span {
    font-size: 48px;
  }
}

@media screen and (min-width: 1000px) {
  .hero-title--main p, .hero-title--main span {
    font-size: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-title--main p, .hero-title--main span {
    font-size: 100px;
  }
}

.hero-title--main span {
  -webkit-text-stroke: 0;
}

@media screen and (min-width: 1000px) {
  .hero-title--main:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .hero-title--main:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .hero-title--main:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .hero-title--main:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

.hero-title--fitness {
  margin-bottom: 216px;
}

.hero-title--fitness:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.hero-title--fitness:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .hero-title--fitness {
    margin-bottom: 24px;
  }
}

@media (min-width: 1200px) {
  .hero-title--fitness p {
    font-size: 80px;
  }
}

@media (min-width: 1440px) {
  .hero-title--fitness p {
    font-size: 96px;
  }
}

.hero-title--body {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .hero-title--body p {
    font-size: 52px;
  }
}

@media screen and (min-width: 600px) {
  .hero-title--body:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .hero-title--body:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

.hero-title--stomache {
  margin-bottom: 270px;
}

.hero-title--stomache:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.hero-title--stomache:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

@media screen and (min-width: 600px) {
  .hero-title--stomache {
    margin-bottom: 24px;
  }

  .hero-title--stomache:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .hero-title--stomache:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

@media screen and (min-width: 768px) {
  .hero-title--stomache {
    margin-bottom: 57px;
  }
}

.hero-title--buttocks {
  margin-bottom: 6px;
}

.hero-title--buttocks:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.hero-title--buttocks:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .hero-title--buttocks:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .hero-title--buttocks:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .hero-title--buttocks span {
    width: 150px;
    display: block;
  }
}

.hero-title-container {
  max-width: 254px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .hero-title-container {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .hero-title-container {
    max-width: 600px;
  }
}

.hero-title-container--buttocks {
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .hero-title-container--buttocks {
    margin-left: auto;
    margin-right: 0;
  }
}

.hero-price {
  color: rgba(0, 0, 0, 0);
  letter-spacing: .03em;
  margin-bottom: 208px;
  font-size: 28px;
}

.hero-price:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.hero-price:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .hero-price {
    margin-bottom: 24px;
    font-size: 38px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-price {
    -webkit-text-stroke: 2px #272727;
    margin-bottom: 42px;
    font-size: 72px;
  }
}

.hero-price--buttocks {
  color: #ff7400;
  margin-bottom: 120px;
}

.hero-price--buttocks:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.hero-price--buttocks:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .hero-price--buttocks {
    margin-bottom: 160px;
  }

  .hero-price--buttocks:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .hero-price--buttocks:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .hero-price--buttocks {
    margin-bottom: 150px;
  }
}

.hero-descr {
  max-width: 259px;
  color: #000;
  text-align: center;
  letter-spacing: -.28px;
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  display: block;
}

@media screen and (max-width: 767px) {
  .hero-descr {
    background-color: #fff;
    border-radius: 0 28px 28px;
    padding: 10px 16px;
    box-shadow: 4px 4px #000;
  }
}

@media screen and (min-width: 768px) {
  .hero-descr {
    margin-right: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }
}

@media screen and (min-width: 1200px) {
  .hero-descr {
    max-width: 400px;
    font-size: 24px;
  }
}

.hero-descr--body {
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .hero-descr--body {
    margin-bottom: 32px;
    font-size: 24px;
  }
}

.hero-descr--fitness {
  max-width: 312px;
}

@media screen and (max-width: 767px) {
  .hero-descr--fitness {
    border-radius: 28px 0 28px 28px;
    box-shadow: 4px 4px #000;
  }
}

@media screen and (min-width: 768px) {
  .hero-descr--fitness {
    max-width: 290px;
    margin-bottom: 29px;
    margin-left: 0;
  }

  .hero-descr--fitness:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .hero-descr--fitness:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

@media screen and (min-width: 1000px) {
  .hero-descr--fitness {
    max-width: 320px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-descr--fitness {
    max-width: 440px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-descr--fitness {
    max-width: 500px;
    font-size: 24px;
  }
}

@media screen and (min-width: 600px) {
  .hero-descr--stomache {
    box-shadow: none;
    max-width: 220px;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 186px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    font-weight: 500;
    line-height: 150%;
  }

  .hero-descr--stomache:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .hero-descr--stomache:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

@media screen and (min-width: 768px) {
  .hero-descr--stomache {
    max-width: 320px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-descr--stomache {
    max-width: 464px;
  }
}

.hero-descr--buttocks {
  max-width: 252px;
  margin-bottom: 100px;
}

.hero-descr--buttocks--orange {
  color: #ff7400;
}

@media screen and (max-width: 767px) {
  .hero-descr--buttocks {
    border-radius: 28px 0 28px 28px;
    box-shadow: 4px 4px #000;
  }
}

@media screen and (min-width: 500px) {
  .hero-descr--buttocks {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 768px) {
  .hero-descr--buttocks {
    max-width: 310px;
    margin-bottom: 0;
    margin-left: 0;
  }

  .hero-descr--buttocks:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .hero-descr--buttocks:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

@media screen and (min-width: 1200px) {
  .hero-descr--buttocks {
    max-width: 464px;
    margin-bottom: -30px;
  }
}

.marquee {
  background-color: #272727;
  padding: 5px 0;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .marquee {
    padding: 10px 0;
  }
}

.marquee_text {
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #f87a11;
  white-space: nowrap;
  font-family: Druk Cyr;
  font-size: 17px;
  font-style: italic;
  font-weight: 600;
  line-height: 1.2;
}

@media screen and (min-width: 1200px) {
  .marquee_text {
    letter-spacing: .5px;
    font-size: 32px;
  }
}

.products {
  background-color: #fff;
  padding: 60px 20px 0;
}

@media screen and (min-width: 768px) {
  .products {
    padding: 60px 40px 0;
  }
}

@media screen and (min-width: 1200px) {
  .products {
    padding: 100px 80px 0;
  }
}

.products-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .products-list {
    flex-wrap: nowrap;
    gap: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .products-list {
    flex-wrap: nowrap;
    gap: 30px;
  }
}

.products-item {
  width: 130px;
  background-color: #ff7400;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 375px) {
  .products-item {
    width: 158px;
  }
}

@media screen and (min-width: 540px) {
  .products-item {
    width: 190px;
  }
}

@media screen and (min-width: 640px) {
  .products-item {
    width: 210px;
  }
}

@media screen and (min-width: 700px) {
  .products-item {
    width: 140px;
  }
}

@media screen and (min-width: 720px) {
  .products-item {
    width: 158px;
    border-radius: 12px;
    box-shadow: 4px 4px #000;
  }
}

@media screen and (min-width: 800px) {
  .products-item {
    width: 170px;
  }
}

@media screen and (min-width: 900px) {
  .products-item {
    width: 190px;
  }
}

@media screen and (min-width: 1000px) {
  .products-item {
    width: 210px;
  }
}

@media screen and (min-width: 1100px) {
  .products-item {
    width: 230px;
  }
}

@media screen and (min-width: 1200px) {
  .products-item {
    width: 270px;
  }
}

@media screen and (min-width: 1440px) {
  .products-item {
    width: 298px;
  }
}

.products-item--notactive {
  pointer-events: none;
  opacity: .35;
}

.products-item__image {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.products-item__text {
  text-align: center;
  color: #272727;
  min-height: 70px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  transition: color .5s;
  display: flex;
}

@media screen and (min-width: 375px) {
  .products-item__text {
    font-size: 16px;
  }
}

@media (min-width: 900px) {
  .products-item__text {
    min-height: 84px;
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .products-item__text {
    min-height: 132px;
    font-size: 26px;
  }
}

.products-item__text:hover {
  color: #fff;
}

.overlay {
  position: relative;
}

.soon {
  position: absolute;
  top: 0;
}

.description {
  background-color: #fff;
  padding: 40px 20px;
}

@media screen and (min-width: 768px) {
  .description {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .description {
    padding: 120px 80px;
  }
}

@media screen and (min-width: 768px) {
  .description-box {
    justify-content: space-between;
    gap: 20px;
    display: flex;
  }
}

@media screen and (min-width: 850px) {
  .description-box {
    gap: 30px;
  }
}

@media screen and (min-width: 1000px) {
  .description-box {
    gap: 40px;
  }
}

@media screen and (min-width: 1300px) {
  .description-box {
    gap: 70px;
  }
}

.description-box--bottom {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .description-box--bottom {
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .description-box--bottom {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1200px) {
  .description-box--fitness {
    padding-bottom: 80px;
  }
}

.description-box--black {
  background-color: #272727;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .description-box--black {
    padding: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .description-box--black {
    padding: 80px;
  }
}

.descr-list {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
}

@media screen and (min-width: 768px) {
  .descr-list {
    max-width: 736px;
    transform: translateY(10px);
  }
}

@media screen and (min-width: 1000px) {
  .descr-list {
    transform: translateY(20px);
  }
}

@media screen and (min-width: 1440px) {
  .descr-list {
    transform: translateY(35px);
  }
}

.descr-list--bootom {
  margin-bottom: 60px;
}

@media screen and (min-width: 1300px) {
  .descr-list--bootom {
    margin-bottom: 80px;
  }
}

.descr-list--black {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

@media screen and (min-width: 400px) {
  .descr-list--black {
    padding-left: 30px;
  }
}

@media screen and (min-width: 600px) {
  .descr-list--black {
    align-items: flex-start;
  }
}

@media screen and (min-width: 900px) {
  .descr-list--black {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-left: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .descr-list--black {
    gap: 117px;
    padding-left: 100px;
  }
}

.descr-title {
  min-width: 240px;
  max-width: 240px;
  display: block;
}

@media screen and (min-width: 768px) {
  .descr-title {
    max-width: 350px;
  }
}

.descr-subtitle {
  color: #000;
  text-transform: none;
  margin-bottom: 36px;
  font-family: Mont;
  font-size: 20px;
  font-weight: 800;
  line-height: 170%;
  display: block;
}

@media screen and (min-width: 768px) {
  .descr-subtitle {
    width: 450px;
  }
}

.descr-item {
  color: #000;
  align-items: flex-start;
  gap: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
}

@media screen and (min-width: 550px) {
  .descr-item {
    gap: 10px;
  }
}

@media screen and (min-width: 1000px) {
  .descr-item {
    font-size: 18px;
    line-height: normal;
  }
}

.descr-item--white {
  color: #fff;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  font-weight: 300;
  line-height: 170%;
}

@media screen and (min-width: 376px) {
  .descr-item--white {
    font-size: 14px;
    font-weight: 500;
  }
}

@media screen and (min-width: 1000px) {
  .descr-item--white {
    font-size: 18px;
  }
}

.descr-icon {
  min-width: 24px;
  min-height: 24px;
}

.result {
  background-color: #fff;
  padding: 0 20px 30px;
}

@media screen and (min-width: 768px) {
  .result {
    padding: 0 40px 60px;
  }
}

@media screen and (min-width: 1200px) {
  .result {
    padding: 0 80px 100px;
  }
}

.result-descr-box {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .result-descr-box {
    flex-direction: row;
  }
}

@media screen and (min-width: 1200px) {
  .result-descr-box {
    gap: 100px;
  }
}

.result-list {
  flex-direction: column;
  gap: 14px;
  display: flex;
}

.result-descr {
  max-width: 440px;
  border-radius: 12px;
  align-self: center;
  padding: 18px 24px;
}

@media screen and (min-width: 768px) {
  .result-descr {
    max-width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .result-list--new {
    flex-flow: wrap;
    gap: 24px 40px;
  }
}

@media screen and (min-width: 1400px) {
  .result-list--new {
    column-gap: 100px;
  }
}

.result-text {
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

@media screen and (min-width: 1200px) {
  .result-text {
    margin-bottom: 48px;
    font-size: 20px;
  }
}

.result-descr-title {
  letter-spacing: .48px;
  text-transform: uppercase;
  color: #272727;
  margin-bottom: 12px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 1000px) {
  .result-descr-title {
    letter-spacing: .96px;
    font-size: 32px;
  }
}

.result-item {
  color: #000;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

@media screen and (min-width: 768px) {
  .result-item {
    width: 300px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1270px) {
  .result-item {
    width: 340px;
  }
}

@media screen and (min-width: 768px) {
  .warning {
    display: none;
  }
}

.warning--desktop {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .warning--desktop {
    display: none;
  }
}

.warning--desktop-container {
  align-items: baseline;
  gap: 8px;
  margin-bottom: 24px;
  display: flex;
}

.warning-desktop-title-orange {
  color: #ff7400;
  letter-spacing: .48px;
  text-transform: uppercase;
  font-family: Druk Cyr;
  font-size: 32px;
  font-weight: 700;
}

.warning--desktop-text {
  color: #272727;
  font-size: 20px;
  font-weight: 800;
  line-height: 150%;
}

.warning-title-orange {
  color: #ff7400;
  margin-top: 60px;
  margin-bottom: 6px;
}

.warning-list {
  flex-direction: column;
  gap: 14px;
  padding-left: 24px;
  display: flex;
}

.warning-item {
  gap: 6px;
  display: flex;
}

.warning-icon {
  min-width: 20px;
  min-height: 20px;
}

.fitness-try-video {
  height: 0;
  padding-top: 30px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.fitness-try-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video {
  background-color: #fff;
  padding: 0 20px 60px;
}

@media screen and (min-width: 768px) {
  .video {
    padding: 0 40px 60px;
  }
}

@media screen and (min-width: 1200px) {
  .video {
    padding: 0 80px 120px;
  }
}

.price {
  background-color: #fff;
  padding: 20px 20px 40px;
}

@media screen and (min-width: 768px) {
  .price {
    padding: 30px 40px 60px;
  }
}

@media screen and (min-width: 1200px) {
  .price {
    padding: 60px 80px 120px;
  }
}

.price-list {
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 42px;
  display: grid;
}

@media (min-width: 768px) {
  .price-list {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
  }
}

@media (min-width: 900px) {
  .price-list {
    gap: 22px;
  }
}

@media (min-width: 1440px) {
  .price-list {
    gap: 42px;
  }
}

.price-item {
  color: #000;
  max-width: 400px;
  border: 2px solid #ff7400;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 24px 36px;
  transition: box-shadow .5s;
  display: flex;
  position: relative;
}

.price-item:hover {
  box-shadow: 4px 4px 0 2px #f87100;
}

@media (min-width: 768px) and (max-width: 999px) {
  .price-item {
    padding: 20px 10px 36px;
  }
}

@media (min-width: 1000px) {
  .price-item {
    padding: 20px 24px 36px;
  }
}

@media screen and (min-width: 1380px) {
  .price-item {
    padding: 32px 30px 42px;
  }
}

.price-item-title {
  letter-spacing: .04em;
  margin-bottom: 18px;
  font-size: 32px;
}

@media (min-width: 768px) and (max-width: 999px) {
  .price-item-title {
    font-size: 24px;
  }
}

.price-item-price {
  letter-spacing: .02em;
  color: #ff7400;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 24px;
  padding: 12px 7px;
  font-family: Druk Cyr;
  font-size: 52px;
  font-style: italic;
  font-weight: 900;
  line-height: 96%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .price-item-price {
    font-size: 28px;
  }
}

@media screen and (min-width: 1000px) {
  .price-item-price {
    font-size: 38px;
  }
}

@media screen and (min-width: 1200px) {
  .price-item-price {
    font-size: 42px;
  }
}

@media screen and (min-width: 1440px) {
  .price-item-price {
    font-size: 56px;
  }
}

.price-item-price span {
  color: #000;
  font-family: Mont;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media (min-width: 768px) and (max-width: 999px) {
  .price-item-price span {
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .price-item-price span {
    font-size: 16px;
  }
}

.price-item-subtitle {
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 800;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .price-item-subtitle {
    font-size: 14px;
  }
}

@media screen and (min-width: 1200px) {
  .price-item-subtitle {
    font-size: 18px;
  }
}

.price-item-descr {
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .price-item-descr {
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .price-item-descr {
    font-size: 16px;
  }
}

.price-item-full {
  text-align: center;
  background-color: rgba(255, 116, 0, .2);
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 24px 20px;
  font-size: 16px;
  font-weight: 800;
}

@media (min-width: 768px) and (max-width: 999px) {
  .price-item-full {
    padding: 14px 10px;
    font-size: 14px;
  }
}

@media screen and (min-width: 1200px) {
  .price-item-full {
    margin-bottom: 32px;
    font-size: 18px;
  }
}

.price-item-full span {
  letter-spacing: .03em;
  color: #272727;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 900;
  line-height: 140%;
}

.pb--descr {
  padding-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .pb--descr {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .pb--descr {
    padding-bottom: 60px;
  }
}

.argument {
  color: #fff;
  background-color: #272727;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .argument {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .argument {
    padding: 80px;
  }
}

.argument-list {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  display: grid;
}

@media screen and (min-width: 400px) {
  .argument-list {
    gap: 30px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 900px) {
  .argument-list {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    padding-left: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .argument-list {
    gap: 60px;
    padding-left: 100px;
  }
}

.argument-item {
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.3;
  display: flex;
}

@media screen and (min-width: 375px) {
  .argument-item {
    font-size: 20px;
  }
}

.arg-icon {
  min-width: 24px;
  min-height: 24px;
}

@media screen and (min-width: 768px) {
  .arg-icon {
    min-width: 32px;
    min-height: 32px;
  }
}

.argument-item-descr {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}

@media screen and (min-width: 768px) {
  .argument-item-descr {
    font-size: 18px;
  }
}

.trainer {
  background-color: #fff;
  padding: 60px 20px;
}

@media screen and (min-width: 768px) {
  .trainer {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .trainer {
    padding: 100px 80px;
  }
}

.trainer-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.trainer-b-0 {
  padding-bottom: 0;
}

@media screen and (min-width: 768px) {
  .trainer--small {
    justify-content: space-between;
    display: flex;
  }
}

.trainer-title {
  color: #272727;
  margin-bottom: 12px;
}

.trainer-subtitle {
  color: #000;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .trainer-subtitle {
    margin-bottom: 32px;
  }
}

.trainer-foto {
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .trainer-foto {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1000px) {
  .trainer-foto {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .trainer-foto--new {
    display: none;
  }
}

.trainer-foto--desktop {
  min-width: 450px;
  max-width: 450px;
  border-radius: 12px;
  margin-bottom: 0;
}

@media screen and (max-width: 999px) {
  .trainer-foto--desktop {
    display: none;
  }
}

@media screen and (min-width: 1100px) {
  .trainer-foto--desktop {
    min-width: 600px;
    max-width: 600px;
  }
}

@media screen and (min-width: 1400px) {
  .trainer-foto--desktop {
    min-width: 750px;
    max-width: 750px;
  }
}

.trainer-foto--desktop--new {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .trainer-foto--desktop--new {
    display: none;
  }
}

@media (min-width: 768px) {
  .trainer-buttocks-list {
    max-width: 452px;
  }
}

.trainer-ed-title {
  color: #000;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .trainer-ed-title {
    text-align: center;
    color: #272727;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 25px;
    font-family: Druk Cyr;
    font-size: 32px;
    font-weight: 700;
  }
}

.trainer-ed-item {
  color: #000;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  display: flex;
}

@media screen and (min-width: 768px) {
  .trainer-ed-item {
    min-width: 300px;
    max-width: 300px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .trainer-ed-item {
    min-width: 375px;
    max-width: 375px;
  }
}

.trainer-ed-list {
  margin-bottom: 30px;
}

.trainer-expirience-list {
  margin-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .trainer-expirience-list {
    max-width: 600px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .trainer-expirience-list {
    max-width: 650px;
  }
}

.trainer-expirience {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .trainer-expirience {
    display: none;
  }
}

.trainer-quote-box {
  position: relative;
}

@media screen and (min-width: 768px) {
  .trainer-quote-box {
    display: none;
  }
}

.trainer-quote-hidden {
  display: none;
}

.trainer-quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-40%);
}

@media screen and (min-width: 1200px) {
  .trainer-quote-icon {
    transform: translateY(-50%)translateX(-50%);
  }
}

.trainer-quote {
  color: #000;
  z-index: 100;
  padding-left: 32px;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.7;
}

@media screen and (min-width: 768px) {
  .trainer-quote {
    padding-left: 0;
  }
}

.foto-anna {
  height: 357px;
  background-color: #595c6e;
  background-image: url("foto_anna_mobile.e2b8badf.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

@media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2x) {
  .foto-anna {
    background-image: url("foto_anna_mobile@2x.6e4d2c28.jpg");
  }
}

@media screen and (min-width: 768px) {
  .foto-anna {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .foto-anna {
    height: 450px;
  }
}

@media screen and (min-width: 600px) {
  .foto-anna {
    height: 500px;
  }
}

.quote {
  background-color: #fff;
  padding: 60px 20px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .quote {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .quote {
    padding: 100px 80px 120px;
  }
}

.quote-icon {
  position: absolute;
  top: 0;
}

@media screen and (min-width: 768px) {
  .trainer-quote-hidden {
    display: block;
  }

  .trainer-quote {
    font-size: 16px;
  }

  .trainer-ed-list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 18px;
    margin-bottom: 60px;
    display: flex;
  }

  .trainer-expirience-list-desc {
    column-count: 2;
  }

  .trainer-desktop-box {
    gap: 65px;
  }

  .quote-desktop {
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 60px;
    display: flex;
  }
}

@media screen and (min-width: 768px) and (min-width: 1000px) {
  .quote-desktop {
    padding: 100px;
  }
}

@media screen and (min-width: 768px) {
  .quote-desktop-img {
    width: 418px;
    display: block;
  }
}

@media screen and (min-width: 768px) and (min-width: 1300px) {
  .quote-desktop-img {
    width: 618px;
  }
}

@media screen and (min-width: 768px) {
  .quote-desktop-box {
    width: 330px;
    padding: 0;
  }
}

@media screen and (min-width: 768px) and (min-width: 1000px) {
  .quote-desktop-box {
    width: 430px;
  }
}

@media screen and (min-width: 768px) {
  .quote-icon-desktop--top {
    position: absolute;
    top: -10%;
    left: -10%;
  }
}

@media screen and (min-width: 768px) {
  .quote-icon-desktop--bottom {
    position: absolute;
    bottom: -10%;
    right: -5%;
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 768px) {
  .trainer-desktop-box {
    align-items: center;
    gap: 35px;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .trainer-desktop-box1 {
    max-width: 400px;
  }
}

@media screen and (min-width: 950px) {
  .trainer-desktop-box1 {
    max-width: 450px;
  }
}

.program, .question {
  color: #fff;
  background-color: #272727;
  padding: 20px 20px 36px;
}

@media screen and (min-width: 768px) {
  .program, .question {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1000px) {
  .program, .question {
    padding: 80px;
  }
}

.question {
  color: #272727;
  background-color: #fff;
}

.program-title {
  margin-bottom: 4px;
}

@media screen and (min-width: 600px) {
  .program-title {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1000px) {
  .program-title {
    max-width: 365px;
    min-width: 365px;
    margin-bottom: 12px;
    display: block;
  }
}

.program-descr-time {
  color: #c2c2c2;
  letter-spacing: .88px;
  text-transform: uppercase;
  margin-bottom: 13px;
  font-family: Druk Cyr;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
}

@media screen and (min-width: 768px) {
  .program-descr-time {
    margin-bottom: 24px;
    font-size: 38px;
  }
}

.program-descr {
  margin-bottom: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media screen and (min-width: 768px) {
  .program-descr {
    margin-bottom: 60px;
    font-size: 18px;
  }
}

.program-box {
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 1000px) {
  .program-box {
    margin-bottom: 80px;
  }
}

.program-item, .question-item {
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid rgba(238, 238, 238, .5);
  border-radius: 12px;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 20px;
}

@media screen and (min-width: 1000px) {
  .program-item, .question-item {
    padding: 25px 40px;
  }
}

.program-item:not(:last-child), .question-item:not(:last-child) {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .program-item:not(:last-child), .question-item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.question-item {
  border: 1px solid #272727;
}

.question-item .question-item-title {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .question-item .question-item-title {
    margin-bottom: 24px;
    font-size: 28px;
  }
}

.program-item[open] {
  border: 1px solid #ff7400;
}

.program-item-title {
  letter-spacing: .48px;
  text-transform: uppercase;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 700;
  transition: color .5s;
}

@media screen and (min-width: 768px) {
  .program-item-title {
    font-size: 32px;
  }
}

.program-item-train-title {
  letter-spacing: -.02em;
  text-transform: none;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .program-item-train-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .program-item-train-title {
    height: 130px;
    letter-spacing: -.48px;
    margin-top: 32px;
    font-size: 24px;
  }
}

.program-item-train-descr {
  color: #dadada;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}

@media screen and (min-width: 768px) {
  .program-item-train-descr {
    width: 520px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) {
  .program-item-train-descr {
    margin-left: auto;
    transform: translateY(-40px);
  }
}

.program-number {
  display: none;
}

.program-button-box {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

@media (min-width: 768px) {
  .program-button-box {
    flex-direction: row;
    justify-content: center;
  }
}

@media screen and (min-width: 1000px) {
  .program-box2 {
    gap: 100px;
    display: flex;
  }

  .program-box1 {
    max-width: 674px;
    padding-top: 12px;
  }

  .program-descr-bold {
    font-size: 28px;
    font-weight: 800;
    line-height: 140%;
  }

  .program-number {
    color: rgba(255, 116, 0, .2);
    font-size: 100px;
    font-style: italic;
    font-weight: 900;
    line-height: 100%;
    display: block;
  }

  .program-number-mobile {
    display: none;
  }

  .program-box-item-desktop {
    align-items: center;
    gap: 54px;
    display: flex;
  }

  .program-details-list {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
}

.program-box-item-mobile {
  gap: 4px;
  display: flex;
}

.question-item-text {
  text-transform: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media (min-width: 768px) {
  .question-item-text {
    font-size: 18px;
  }
}

.question-item-title {
  letter-spacing: .03em;
  text-transform: none;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.27;
}

@media (min-width: 768px) {
  .question-item-title {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .question-item-title {
    font-size: 32px;
  }
}

.inventory {
  background-color: #fff;
  padding: 60px 20px 102px;
}

@media screen and (min-width: 768px) {
  .inventory {
    padding: 80px 40px 60px;
  }
}

@media screen and (min-width: 1200px) {
  .inventory {
    padding: 160px 80px 100px;
  }
}

@media screen and (min-width: 768px) {
  .inventory-box {
    gap: 60px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .inventory-box {
    gap: 113px;
  }
}

@media screen and (min-width: 768px) {
  .inventory-list {
    justify-content: space-between;
    display: flex;
  }
}

.inventory-descr {
  color: #000;
  font-family: Mont;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media screen and (min-width: 1200px) {
  .inventory-descr {
    font-size: 20px;
  }
}

.inventory-box1 {
  margin-bottom: 54px;
}

@media screen and (min-width: 1200px) {
  .inventory-box1 {
    width: 520px;
    margin-bottom: 68px;
  }
}

.inventory-image {
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

.inventory-item-title {
  color: #272727;
  letter-spacing: .48px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .inventory-item-title {
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .inventory-item-title {
    width: 280px;
    margin-bottom: 38px;
    font-size: 32px;
  }
}

.bottom-0 {
  margin-bottom: 0;
}

.gift {
  background-color: #272727;
  padding: 33px 20px 60px;
}

@media screen and (min-width: 768px) {
  .gift {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .gift {
    padding: 65px 80px;
  }
}

.gift_title {
  color: #ff7400;
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .gift_title {
    color: #fff;
  }
}

.gift-subtitle {
  color: #fff;
  letter-spacing: .48px;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 1200px) {
  .gift-subtitle {
    color: #ff7400;
    margin-bottom: 32px;
    font-size: 48px;
  }
}

.gift-image {
  margin-bottom: 24px;
}

@media screen and (min-width: 1200px) {
  .gift-image {
    margin-bottom: 0;
  }
}

.gift-descr {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media screen and (min-width: 1200px) {
  .gift-descr {
    font-size: 18px;
  }
}

.gift-image {
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1199px) {
  .gift-descr-hidden {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .gift-descr-hidden1 {
    display: none;
  }

  .gift-desktop-box {
    align-items: center;
    gap: 129px;
    display: flex;
  }

  .gift-title-box {
    width: 510px;
  }
}

.contraindication {
  color: #fff;
  background-color: #272727;
  padding: 60px 20px 40px;
}

@media screen and (min-width: 768px) {
  .contraindication {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .contraindication {
    padding: 100px 80px 120px;
  }
}

.contraindication-list {
  grid-template-columns: 1fr 1fr;
  gap: 40px 30px;
  display: grid;
}

@media screen and (min-width: 500px) {
  .contraindication-list {
    column-gap: 40px;
  }
}

@media screen and (min-width: 768px) {
  .contraindication-list {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .contraindication-list {
    gap: 80px;
  }
}

.contraindication-item {
  text-align: center;
}

.contraindication-foto {
  margin-bottom: 12px;
}

@media screen and (min-width: 1200px) {
  .contraindication-foto {
    margin-bottom: 24px;
  }
}

.contraindication-subtitle {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .contraindication-subtitle {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .contraindication-subtitle {
    font-size: 26px;
  }
}

@media screen and (min-width: 1440px) {
  .contraindication-subtitle {
    font-size: 32px;
  }
}

.contraindication-descr {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (min-width: 1200px) {
  .contraindication-descr {
    text-align: center;
    font-size: 18px;
  }
}

.package {
  background-color: #fff;
  padding: 60px 20px;
}

@media screen and (min-width: 768px) {
  .package {
    padding: 60px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .package {
    padding: 120px 80px;
  }

  .package-title-descr {
    display: none;
  }
}

.package-premium-icon {
  width: 90px;
  height: 90px;
  color: #272727;
  text-align: center;
  text-shadow: 0 4px 4px rgba(39, 39, 39, .1);
  letter-spacing: .64px;
  text-transform: uppercase;
  background: linear-gradient(184deg, #6fc7b8 0%, #fff 100%);
  border-radius: 100px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 105%;
  display: flex;
  position: absolute;
  top: -3%;
  right: -1%;
  transform: rotate(19.378deg);
}

@media screen and (min-width: 1200px) {
  .package-premium-icon {
    width: 136px;
    height: 136px;
    padding: 16px 24px;
    font-size: 32px;
    top: -6%;
    right: -15%;
  }
}

.package-list {
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  display: flex;
}

@media screen and (min-width: 900px) {
  .package-list {
    flex-direction: row;
  }
}

.package-item {
  max-width: 480px;
  border: 1px solid #6fc7b8;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 12px 32px;
  position: relative;
}

@media screen and (min-width: 900px) {
  .package-item {
    width: 407px;
  }
}

.package-item-base {
  border: 1px solid #f18ea3;
}

.package-item-box {
  text-align: center;
  background-color: #6fc7b8;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 24px 0;
}

.package-item-box-base {
  background-color: #f18ea3;
}

.package-title {
  color: #fff;
  text-shadow: 0 4px 4px rgba(39, 39, 39, .1);
  letter-spacing: 1.04px;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-family: Druk Cyr;
  font-size: 52px;
  font-style: italic;
  font-weight: 900;
  line-height: 96%;
}

@media (min-width: 900px) and (max-width: 1100px) {
  .package-title {
    font-size: 42px;
  }
}

.package-item-descr-list {
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .package-item-descr-list {
    gap: 9px;
  }
}

.package-item-descr {
  color: #272727;
  align-items: flex-start;
  gap: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 170%;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .package-item-descr {
    font-size: 16px;
  }
}

.package-item-descr-cross {
  color: #bfbfbf;
}

.package-test-button {
  width: 129px;
  background-color: #6fc7b8;
  font-size: 16px;
}

.package-test-button:hover, .package-test-button:focus {
  background-color: #029179;
}

.package-base-button {
  width: 129px;
  background-color: #f18ea3;
  font-size: 16px;
}

.package-base-button:hover, .package-base-button:focus {
  background-color: #ee6c88;
}

.package-item-premium {
  border: 1px solid #ff7400;
}

.package-item-box-premium {
  background-color: #ff7400;
}

.package-title-premium {
  margin-bottom: 6px;
}

@media screen and (min-width: 1200px) {
  .package-title-premium {
    margin-bottom: 24px;
  }
}

.package-title-descr {
  color: #272727;
  text-shadow: 0 4px 4px rgba(39, 39, 39, .1);
  letter-spacing: .48px;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-family: Druk Cyr;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 96%;
}

.package-premium-button {
  width: 129px;
  background-color: #ff7400;
  font-size: 16px;
}

.package-price {
  width: 193px;
  color: #f18ea3;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #272727;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  padding: 11px 26px 17px;
  font-family: Druk Cyr;
  font-size: 40px;
  font-style: italic;
  font-weight: 1000;
  display: block;
}

.package-price-blue {
  color: #6fc7b8;
}

.package-price-orange {
  color: #ff7400;
}

.feedback {
  background-color: #fff;
  padding: 40px 20px 100px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .feedback {
    padding: 0 60px 120px;
  }
}

@media screen and (min-width: 1200px) {
  .feedback {
    padding: 0 80px 120px;
  }
}

@media screen and (min-width: 768px) {
  .feedback-pd {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .feedback-pd {
    padding-top: 80px;
  }
}

.feedback-item {
  max-height: 340px;
  border: 2px solid #ff7400;
  border-radius: 10px;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}

.feedback-item::-webkit-scrollbar {
  width: 10px;
  -webkit-appearance: none;
}

.feedback-item::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.feedback-item::-webkit-scrollbar-thumb {
  background-color: #ff7400;
  border-radius: 100px;
  box-shadow: inset 2px 2px 5px rgba(255, 255, 255, .5);
}

.wrapper .slick-track {
  gap: 30px;
  display: flex;
}

.feedback-container {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.feedback-descr {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

.feedback-image {
  width: 86px;
  height: 16px;
}

.wrapper .slick-dots li button:before {
  color: #ff7400;
  opacity: 1;
  margin-top: 24px;
  font-size: 20px;
}

.wrapper .slick-slide {
  min-height: 340px;
}

.wrapper .slick-next {
  width: 24px;
  height: 34px;
  background: url("arrow-next.44fbed91.svg") no-repeat;
  right: -45px;
}

.wrapper .slick-prev {
  width: 24px;
  height: 34px;
  background: url("arrow-prev.97df72c9.svg") no-repeat;
  left: -35px;
}

.wrapper .slick-next:before, .wrapper .slick-prev:before {
  content: "";
}

.wrapper .slick-prev:hover, .wrapper .slick-prev:focus {
  background: url("arrow-prev.97df72c9.svg") no-repeat;
}

.wrapper .slick-next:hover, .wrapper .slick-next:focus {
  background: url("arrow-next.44fbed91.svg") no-repeat;
}

.contact {
  color: #fff;
  background-color: #272727;
  padding: 20px 20px 0;
}

@media screen and (min-width: 768px) {
  .contact {
    padding: 60px 40px 0;
  }
}

@media screen and (min-width: 1200px) {
  .contact {
    padding: 40px 80px 0;
  }
}

.contact-box {
  border-bottom: 1px solid #fff;
  padding-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .contact-box {
    align-items: start;
    gap: 30px;
    padding-bottom: 40px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .contact-box {
    gap: 113px;
    padding-bottom: 80px;
  }
}

.contact-info {
  margin-bottom: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}

@media screen and (min-width: 1200px) {
  .contact-info {
    min-width: 343px;
    max-width: 343px;
    font-size: 16px;
    display: block;
  }
}

.contact-link {
  text-underline-offset: 6px;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 800;
  line-height: 150%;
  text-decoration-line: underline;
  transition: all .5s;
  display: flex;
}

.contact-link:hover, .contact-link:focus {
  color: #ff7400;
  text-decoration: underline #f87a10;
  text-decoration-thickness: 2px;
}

@media screen and (min-width: 1200px) {
  .contact-link {
    font-size: 22px;
  }
}

.contact-item {
  margin-bottom: 20px;
}

.footer {
  color: #fff;
  background-color: #272727;
  padding: 18px 20px 16px;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 60px 40px 33px;
  }
}

@media screen and (min-width: 1200px) {
  .footer {
    padding: 60px 80px 32px;
  }
}

.footer-create-link {
  cursor: pointer;
  font-size: 10px;
  transition: color .5s;
}

.footer-create-link:hover {
  color: #ff7400;
}

@media screen and (min-width: 375px) {
  .footer-create-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .footer-create-link-hidden-mobile {
    display: none;
  }

  .footer-create-link {
    margin-bottom: 6px;
  }

  .footer-create-box {
    justify-content: space-between;
    display: flex;
  }

  .footer-create-box1 {
    flex-direction: column;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .footer-create-link-hidden-desktop {
    display: none;
  }

  .footer-create-box1 {
    justify-content: space-between;
    display: flex;
  }
}

/*# sourceMappingURL=buttocks.55d9cd95.css.map */
