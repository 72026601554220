.contact {
  background-color: $dark-bg-color;
  color: $white-color;
  padding: 20px 20px 0;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 40px 80px 0;
  }
}

.contact-box {
  padding-bottom: 60px;
  border-bottom: 1px solid $white-color;
}

.contact-box {
  @media screen and (min-width: 768px) {
    display: flex;
    gap: 30px;
    align-items: start;
    padding-bottom: 40px;
  }
  @media screen and (min-width: 1200px) {
    gap: 113px;

    padding-bottom: 80px;
  }
}

.contact-info {
  margin-bottom: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;

  @media screen and (min-width: 1200px) {
    display: block;
    min-width: 343px;
    max-width: 343px;
    font-size: 16px;
  }
}

.contact-link {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 800;
  line-height: 150%;
  text-decoration-line: underline;
  text-underline-offset: 6px;
  transition: all 0.5s ease;
  &:hover,
  &:focus {
    color: $accent-bg-color;
    text-decoration: underline #f87a10 2px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 22px;
  }
}

.contact-item {
  margin-bottom: 20px;
}
