.argument {
  background-color: $dark-bg-color;
  color: $white-color;
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 60px 40px 60px;
  }

  @media screen and (min-width: 1200px) {
    padding: 80px;
  }
}

.argument-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (min-width: 400px) {
    padding-left: 30px;
    gap: 30px;
  }

  @media screen and (min-width: 900px) {
    padding-left: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 100px;
    gap: 60px;
  }
}

.argument-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.3;

  @media screen and (min-width: 375px) {
    font-size: 20px;
  }
}

.arg-icon {
  min-width: 24px;
  min-height: 24px;
  @media screen and (min-width: 768px) {
    min-width: 32px;
    min-height: 32px;
  }
}

.argument-item-descr {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}
