.back-to-top-container {
  position: relative;
}
.back-to-top {
  position: fixed;
  display: flex;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background-color: $grey-text-color;
  border: none;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s ease;
  transform: translateX(-10px) translateY(-50px);
}

.hidden-scroll {
  opacity: 0%;
}
.back-to-top-icon {
  fill: $accent-bg-color;
}

.back-to-top:hover {
  opacity: 60%;
}
